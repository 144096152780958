<script
    lang="ts"
    setup
>
    type Props = {
        tag?: string
        active?: boolean
        disabled?: boolean
        loading?: boolean
        clearable?: boolean
        dropDownActivator?: boolean
        contentClass?: string
    }

    type Emit = {
        (event: 'update:active', value: boolean): void
        (event: 'clear'): void
    }

    const props = withDefaults(defineProps<Props>(), {
        tag: 'button',
        active: false,
        disabled: false,
        loading: false,
        clearable: false,
        dropDownActivator: false,
        contentClass: undefined,
    })
    const emit = defineEmits<Emit>()

    const activeModel = useSyncProp(props, 'active', emit)

    const chipClass = computed<string>(() => {
        let classes = `
            appearance-none
            outline-none
            border-none
            whitespace-nowrap
            relative
            flex
            items-center
            justify-center
            h-[30px]
            py-1.5
            px-3
            rounded-[8px]
            transition-colors
        `

        if (props.disabled) {
            classes += ' bg-[#f5f5f5] text-[#a7a7a7] cursor-not-allowed'
        } else if (activeModel.value) {
            classes += ' bg-[#b5bccf] cursor-pointer'
        } else {
            classes += ' bg-[#e3e5eb] cursor-pointer hover:bg-[#ced2dc] active:bg-[#b5bccf]'
        }

        return classes
    })

    const iconCloseStaticColor = computed<string | undefined>(() => activeModel.value ? '#000' : undefined)
</script>

<template>
    <component
        :is="props.tag"
        type="button"
        :class="chipClass"
        :disabled="props.disabled || props.loading"
        @click="activeModel = !activeModel"
    >
        <AppIconLoading
            v-if="props.loading"
            key="loading"
            class="absolute"
            size="16"
            color="#000"
            :animate="true"
        />

        <span
            key="content"
            :class="[
                'flex gap-2 text-[14px]',
                { 'opacity-0': props.loading },
                props.contentClass,
            ]"
        >
            <slot />

            <AppIconChevronDown
                v-if="props.dropDownActivator"
                key="icon-arrow"
                size="16"
                color="#000"
                :rotate="activeModel"
            />

            <span class="group">
                <AppIconClose
                    v-if="props.clearable"
                    key="icon-clear"
                    size="16"
                    :color="iconCloseStaticColor"
                    class="group-hover:[&>path]:fill-black"
                    @click.prevent.stop="emit('clear')"
                />
            </span>
        </span>
    </component>
</template>
